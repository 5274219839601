<template>
  <div>
    <register-form-page :type="regType" :referal-info="referalInfo" v-if="validReferalCode && isMounted" />
    <div class="vx-row no-gutter items-center justify-center p-6" v-if="isMounted && !validReferalCode">
      <div class="vx-col sm:w-5/6 md:w-2/3 lg:w-1/2 xl:w-2/5 xxl:w-1/3 m-0">
        <vx-card class="login-card">
          <div class="invalid-referal-code">
            <vs-alert class="h-full mb-3" color="danger">
              El código de invitación <span class="bold">{{code}}</span> no es válido.
            </vs-alert>
            <p>Por favor verifica el código que te compartieron o comunícate con nosotros a través del Chat.</p>
            <div class="mt-base mb-3">
              <vs-button type="line" color="dark" class="mb-1 p-1 link-btn" @click="goToRegisterNoCode()" >¿Deseas registrarte sin un código de Re(d)ferido?</vs-button>
            </div>
          </div>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import RegisterFormPage from "@views/register/RegisterFormPage";
export default {
  name: "RegisterFromReferalPage",
  props: ["code"],
  components: {RegisterFormPage},
  data() {
    return {
      isMounted: false,
      validReferalCode: false,
      regType: null,
      referalInfo: {
        id: null,
        name: null,
        uri: null,
        type: null,
        referal_type: null,
      }
    }
  },
  async beforeMount(){
    this.isMounted = false
    await this.checkCode();
    this.isMounted = true
  },
  methods:{
    async checkCode(){
      if(this.$props.code != null && this.$props.code !== ""){
        this.showLoading(true)
        this.validReferalCode = await this.validateReferalCode(this.code);
        if(this.validReferalCode){
          this.regType = (this.referalInfo.referal_type === 3) ? "instalador" : "inversionista";
        }
        this.showLoading(false)
      }
    },
    async validateReferalCode(referalCode){
      try {
        const res = await this.publicApiGet(`api/v2/preregister/validateReferalCode/${referalCode}`);
        this.referalInfo = res.data.referal;
        return true;
      }
      catch (e) {
        return false;
      }
    },
    async goToRegisterNoCode(){
      await this.$router.replace({name: 'nuevoRegistro', params: {type: 'inversionista'}});
    },
  }
}
</script>

<style scoped>

</style>